import { Button, Spinner, Well } from '@axieinfinity/mochi'
import { ExternalLinkIcon, NotebookIcon } from '@axieinfinity/mochi-icons'
import { Pair } from '@uniswap/v2-sdk'
import WalletCongrasImg from 'assets/images/wallet-congrats.png'
import { tokenAddressByChain } from 'constants/tokens'
import { AppContext } from 'index'
import { useContext, useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { useWalletModalToggle } from 'state/application/hooks'
import styled, { ThemeContext } from 'styled-components'
import BirdWithBoardPng from '../../assets/images/bird-with-board.png'
import { AutoColumn } from '../../components/Column'
import { SwapPoolTabs } from '../../components/NavigationTabs'
import FullPositionCard from '../../components/PositionCard'
import { RowBetween, RowFixed } from '../../components/Row'
import { Dots } from '../../components/swap/styleds'
import { useV2Pairs } from '../../hooks/useV2Pairs'
import { useActiveWeb3React } from '../../hooks/web3'
import { toV2LiquidityToken, useTrackedTokenPairs } from '../../state/user/hooks'
import { useTokenBalancesWithLoadingIndicator } from '../../state/wallet/hooks'
import { ExternalLink, TYPE } from '../../theme'
import { Ronin } from 'constants/ron'

const analyticsDomain = process.env.REACT_APP_KATANA_ANALYTICS_DOMAIN ?? ''

const PageWrapper = styled(AutoColumn)`
  max-width: 720px;
  width: 100%;
`

const TitleRow = styled(RowBetween)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    flex-direction: column;
    `};
  margin-top: 1rem;
`

const ButtonRow = styled(RowFixed)`
  gap: 8px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    justify-content: flex-start ;
    `};
`

const MainCard = styled.div`
  box-shadow: var(--mc-theme-shadow-2);
  background-color: #fff;
  border: 1px solid var(--mc-color-light-3);
  padding: 20px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const NoUnderlineNavLink = styled(NavLink)`
  text-decoration: none !important;
`

const NoUnderlineExternalLink = styled(ExternalLink)`
  text-decoration: none !important;
  color: var(--mc-color-blue-5);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
`

const TitleContent = styled.div`
  font-size: var(--mc-font-size-x-large);
  line-height: var(--mc-line-height-3x-large);
  font-weight: var(--mc-font-weight-semi-bold);
  color: var(--mc-color-light-9);
  text-align: left;
  width: 100%;
  @media (min-width: 768px) {
    width: auto;
  }
`
const Container = styled(AutoColumn)`
  width: 100%;
`
const NoUnderlineLink = styled(ExternalLink)`
  text-decoration: none;
`
const RequiredConnectWallet = styled.div`
  background-color: var(--mc-color-white);
  border-width: var(--mc-space-inset-x);
  border-color: var(--mc-color-light-3);
  border-radius: var(--mc-space-inset-12x);
  padding-top: var(--mc-space-stack-32x);
  padding-bottom: var(--mc-space-stack-32x);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 #0000, 0 0 #0000, var(--mc-theme-shadow-2);
`

const WalletConnectImg = styled.img`
  margin-bottom: var(--mc-space-stack-32x);
`
const Instruction = styled.div`
  color: var(--mc-color-light-7);
  font-size: var(--mc-font-size-medium);
  line-height: var(--mc-line-height-large);
  text-align: center;
`
const ButtonConnect = styled(Button)`
  margin-top: var(--mc-space-stack-20x);
  margin-bottom: var(--mc-space-stack-12x);
`
const ButtonConnectWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: var(--mc-space-inset-4x);
`
const ButtonAddLiquidity = styled(Button)`
  margin-bottom: 28px;
  margin-top: var(--mc-space-stack-16x);
`
const NoDataFoundImg = styled.img`
  margin-top: var(--mc-space-stack-8x);
`
const MessageNoDataFound = styled.span`
  font-size: var(--mc-font-size-large);
  line-height: var(--mc-line-height-x-large);
  font-weight: var(--mc-font-weight-semi-bold);
  color: var(--mc-color-light-7);
`
const StyleWell = styled(Well)`
  width: 100% !important;
`
export default function Pool() {
  const theme = useContext(ThemeContext)
  const { account, chainId } = useActiveWeb3React()

  const toggleWalletModal = useWalletModalToggle()
  const { pendingLogin } = useContext(AppContext)
  // fetch the user's balances of all tracked V2 LP tokens
  const trackedTokenPairs = useTrackedTokenPairs()

  const tokenPairsWithLiquidityTokens = useMemo(
    () => trackedTokenPairs.map((tokens) => ({ liquidityToken: toV2LiquidityToken(tokens), tokens })),
    [trackedTokenPairs]
  )
  const liquidityTokens = useMemo(() => tokenPairsWithLiquidityTokens.map((tpwlt) => tpwlt.liquidityToken), [
    tokenPairsWithLiquidityTokens,
  ])
  const [v2PairsBalances, fetchingV2PairBalances] = useTokenBalancesWithLoadingIndicator(
    account ?? undefined,
    liquidityTokens
  )

  // fetch the reserves for all V2 pools in which the user has a balance
  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
        v2PairsBalances[liquidityToken.address]?.greaterThan('0')
      ),
    [tokenPairsWithLiquidityTokens, v2PairsBalances]
  )

  const v2Pairs = useV2Pairs(liquidityTokensWithBalances.map(({ tokens }) => tokens))
  const v2IsLoading =
    fetchingV2PairBalances || v2Pairs?.length < liquidityTokensWithBalances.length || v2Pairs?.some((V2Pair) => !V2Pair)

  const allV2PairsWithLiquidity = v2Pairs.map(([, pair]) => pair).filter((v2Pair): v2Pair is Pair => Boolean(v2Pair))

  // show liquidity even if its deposited in rewards contract
  // const stakingInfo = useStakingInfo()
  // const stakingInfosWithBalance = stakingInfo?.filter((pool) =>
  //   JSBI.greaterThan(pool.stakedAmount.quotient, BIG_INT_ZERO)
  // )
  // const stakingPairs = useV2Pairs(stakingInfosWithBalance?.map((stakingInfo) => stakingInfo.tokens))
  const stakingPairs: any = []

  // remove any pairs that also are included in pairs with stake in mining pool
  const v2PairsWithoutStakedAmount = allV2PairsWithLiquidity.filter((v2Pair) => {
    return (
      stakingPairs
        ?.map((stakingPair: any) => stakingPair[1])
        .filter((stakingPair: any) => stakingPair?.liquidityToken.address === v2Pair.liquidityToken.address).length ===
      0
    )
  })

  return (
    <>
      <PageWrapper>
        <SwapPoolTabs active={'pool'} />

        <AutoColumn gap="lg" justify="center">
          <Container gap="md">
            <TitleRow padding={'0'}>
              <TitleContent>Your liquidity</TitleContent>

              <ButtonRow>
                <NoUnderlineLink target="_blank" href="https://axie-infinity.gitbook.io/katana-docs/">
                  <Button intent="primary" minimal leftIcon={NotebookIcon}>
                    Learn about liquidity
                  </Button>
                </NoUnderlineLink>

                {(allV2PairsWithLiquidity?.length > 0 || stakingPairs?.length > 0) && (
                  <NoUnderlineNavLink to={`/add/${Ronin.onChain(chainId).symbol}`}>
                    <Button intent="primary">Add liquidity</Button>
                  </NoUnderlineNavLink>
                )}
              </ButtonRow>
            </TitleRow>

            {!account ? (
              <RequiredConnectWallet>
                <WalletConnectImg width={200} alt="" src={WalletCongrasImg} />
                <Instruction>Connect to Ronin Wallet to view your liquidity.</Instruction>
                <ButtonConnect disabled={pendingLogin} onClick={toggleWalletModal} intent="secondary">
                  <ButtonConnectWrapper>
                    {pendingLogin && <Spinner intent="primary" size="small" />}
                    Connect Ronin Wallet
                  </ButtonConnectWrapper>
                </ButtonConnect>
              </RequiredConnectWallet>
            ) : v2IsLoading ? (
              <MainCard>
                <TYPE.body color={theme.text3} textAlign="center">
                  <Dots>Loading</Dots>
                </TYPE.body>
              </MainCard>
            ) : allV2PairsWithLiquidity?.length > 0 || stakingPairs?.length > 0 ? (
              <MainCard>
                <StyleWell intent="primary">
                  <NoUnderlineExternalLink href={`${analyticsDomain}/account/${account}`}>
                    View all accrued fees and analytics
                    <ExternalLinkIcon size={20} />
                  </NoUnderlineExternalLink>
                </StyleWell>

                {v2PairsWithoutStakedAmount.map((v2Pair) => (
                  <FullPositionCard key={v2Pair.liquidityToken.address} pair={v2Pair} />
                ))}
              </MainCard>
            ) : (
              <MainCard>
                <NoDataFoundImg src={BirdWithBoardPng} width={200} />

                <MessageNoDataFound>No liquidity found</MessageNoDataFound>

                <NoUnderlineNavLink to={`/add/${Ronin.onChain(chainId).symbol}`}>
                  <ButtonAddLiquidity intent="primary">Add liquidity</ButtonAddLiquidity>
                </NoUnderlineNavLink>
              </MainCard>
            )}
          </Container>
        </AutoColumn>
      </PageWrapper>
    </>
  )
}
