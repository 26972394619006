import { Spinner } from '@axieinfinity/mochi'
import { FilledWalletIcon } from '@axieinfinity/mochi-icons'
import useScrollPosition from '@react-hook/window-scroll'
import { useMemo, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Text } from 'rebass'
import styled from 'styled-components/macro'
import { formatCurrencyAmount } from 'utils/formatCurrencyAmount'
import { useActiveWeb3React } from '../../hooks/web3'
import { useSignInModalToggle, useWalletModalToggle } from '../../state/application/hooks'
import { isTransactionRecent, useAllTransactions } from '../../state/transactions/hooks'
import { useETHBalances } from '../../state/wallet/hooks'
import ClaimModal from '../claim/ClaimModal'
import Modal from '../Modal'
import Row, { RowFixed } from '../Row'
import Web3Status, { newTransactionsFirst } from '../Web3Status'
import { NavBar } from './NavBar'
import UniBalanceContent from './UniBalanceContent'
import SignInModal from 'components/SignInModal'

const HeaderFrame = styled.div<{ showBackground: boolean }>`
  display: grid;
  grid-template-columns: 116px 1fr 211px;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  top: 0;
  position: relative;
  padding: 12px 28px;
  z-index: 21;
  position: relative;
  /* Background slide effect on scroll. */
  background-image: ${({ theme }) => `linear-gradient(to bottom, transparent 50%, ${theme.bg0} 50% )}}`};
  background-position: ${({ showBackground }) => (showBackground ? '0 -100%' : '0 0')};
  background-size: 100% 200%;
  box-shadow: 0px 0px 0px 1px ${({ theme, showBackground }) => (showBackground ? theme.bg2 : 'transparent;')};
  transition: background-position 0.1s, box-shadow 0.1s;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding:  20px 12px;
    grid-template-columns: auto 1fr;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding:  20px 12px;
  `}
  .desktop-element {
    &-flex {
      display: flex;
    }
    display: block;
  }
  .mobile-element {
    &,
    &-flex {
      display: none;
    }
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
  .desktop-element {
    &, &-flex {
      display: none;
    }
  }
  .mobile-element {
    &-flex {
      display: flex
    }
    display: block;
  }
  `}
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-self: flex-end; */

  ${({ theme }) => theme.mediaWidth.upToMedium`    
    flex-direction: row;
    justify-content: space-between;
    justify-self: center;
    width: 100%;
    max-width: 960px;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100vw;
    z-index: 99;
    height: 64px;
  `}
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;

  /* addresses safari's lack of support for "gap" */
  & > *:not(:first-child) {
    margin-left: 8px;
  }

  /* ${({ theme }) => theme.mediaWidth.upToMedium`
    // flex-direction: row-reverse;
    align-items: center;
  `}; */
`

const HeaderRow = styled(RowFixed)`
  ${({ theme }) => theme.mediaWidth.upToMedium`
   width: 100%;
  `};
`

const HeaderLinks = styled(Row)`
  justify-self: start;
  width: fit-content;
  height: 100%;
  display: grid;
  grid-auto-flow: column;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    justify-self: flex-end;
  `};
`

const AccountElement = styled.div<{ active: boolean; account?: string | null }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${(account) => {
    return account.account ? 'var(--mc-color-light-2)' : 'transparent'
  }};
  border-radius: 8px;
  white-space: nowrap;
  /* width: auto; */
  cursor: pointer;
  justify-content: end;

  :hover,
  :focus {
    background-color: var(--mc-color-light-3);
  }
`

const BalanceText = styled(Text)`
  height: 36px;
  font-weight: 600;
  padding-top: var(--mc-space-stack-8x);
  padding-left: var(--mc-space-inline-8x);
  padding-bottom: var(--mc-space-stack-8x);
  padding-right: var(--mc-space-inline-12x);
  flex-shrink: 0;
  font-weight: 600;

  /* ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `}; */
`

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-self: center;
  `};
  :hover {
    cursor: pointer;
  }
`

const UniIcon = styled.div`
  transition: transform 0.3s ease;
  /* :hover {
    transform: rotate(-5deg);
  } */

  display: flex;
  align-items: center;
  color: var(--mc-color-light-9);
  gap: 4px;
  font-weight: 700;
`
export const StyledMenuButton = styled.button`
  position: relative;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  height: 35px;
  background-color: ${({ theme }) => theme.bg2};
  margin-left: 8px;
  padding: 0.15rem 0.5rem;
  border-radius: 0.5rem;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
    background-color: ${({ theme }) => theme.bg4};
  }

  svg {
    margin-top: 2px;
  }
  > * {
    stroke: ${({ theme }) => theme.text1};
  }
`
//<FilledWalletIcon className="ml-8 w-16 md:w-24 text-basic-7" />
const FilledWalletIconWrapper = styled.div`
  margin-left: 8px;
  color: var(--mc-color-light-7);
  height: 24px;
  display: flex;
  align-items: center;
`
const FilledWalletIconWrapperSmall = styled(FilledWalletIconWrapper)`
  @media (min-width: 768px) {
    display: none;
  }
`
const FilledWalletIconWrapperMedium = styled(FilledWalletIconWrapper)`
  @media (max-width: 768px) {
    display: none;
  }
`

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const AccountSection = styled.div`
  display: flex;
  align-items: center;
`
const PendingTransaction = styled.div`
  border-width: 1px;
  border-top-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-color: var(--mc-color-light-3);
  margin-right: 4px;
  margin-left: 8px;
`

const TransactionWrapper = styled.div`
  height: 20px;
  display: flex;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  background-color: white;
  border-radius: 4px;
  margin-right: 12px;
  gap: 4px;
`

const PendingAmount = styled.div`
  color: var(--mc-color-blue-5);
  font-size: 10px;
  line-height: 16px;
`
export default function Header() {
  const { account, chainId } = useActiveWeb3React()

  const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']

  const toggleWalletModal = useWalletModalToggle()
  const toggleSignInModal = useSignInModalToggle()

  const [showUniBalanceModal, setShowUniBalanceModal] = useState(false)

  const scrollY = useScrollPosition()

  const allTransactions = useAllTransactions()

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])

  const pending = sortedRecentTransactions.filter((tx) => !tx.receipt).map((tx) => tx.hash)

  const hasPendingTransactions = !!pending.length

  return (
    <HeaderFrame showBackground={scrollY > 45}>
      <ClaimModal />
      <Modal isOpen={showUniBalanceModal} onDismiss={() => setShowUniBalanceModal(false)}>
        <UniBalanceContent setShowUniBalanceModal={setShowUniBalanceModal} />
      </Modal>
      <HeaderRow className="removable-element">
        <Title href=".">
          <UniIcon>
            <img width="32px" src="https://cdn.axieinfinity.com/dapps/profit.png" alt="logo" />
            <span>Katana</span>
          </UniIcon>
        </Title>
      </HeaderRow>
      {!isMobile ? (
        <HeaderControls>
          <HeaderLinks>
            <NavBar />
          </HeaderLinks>
        </HeaderControls>
      ) : null}

      {!(window as any).isWalletApp && (
        <HeaderElement className="removable-element">
          <HeaderWrapper>
            <AccountElement
              active={!!account}
              style={{ pointerEvents: 'auto' }}
              onClick={() => {
                account ? toggleWalletModal() : toggleSignInModal()
              }}
              account={account}
            >
              {account && (
                <AccountSection>
                  {hasPendingTransactions && (
                    <PendingTransaction>
                      <TransactionWrapper>
                        <Spinner size="small" />
                        <PendingAmount>{pending?.length} pending</PendingAmount>
                      </TransactionWrapper>
                    </PendingTransaction>
                  )}
                  <FilledWalletIconWrapperSmall>
                    <FilledWalletIcon size={16} />
                  </FilledWalletIconWrapperSmall>
                  <FilledWalletIconWrapperMedium>
                    <FilledWalletIcon size={24} />
                  </FilledWalletIconWrapperMedium>
                  <BalanceText>{userEthBalance ? formatCurrencyAmount(userEthBalance) : '--'} RON</BalanceText>
                </AccountSection>
              )}
              <Web3Status />
            </AccountElement>
          </HeaderWrapper>
        </HeaderElement>
      )}
    </HeaderFrame>
  )
}
