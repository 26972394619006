import { QRCodeCanvas } from 'qrcode.react'
import React, { FC, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import roninLogo from '../../assets/svg/ronin-logo.svg'
import { RoninConnector } from 'connectors/RoninConnector'
import { SUPPORTED_CHAIN_IDS } from 'connectors'
import { RoninMobileConnector } from '@axieinfinity/connect/dist/RoninMobileConnector'
import { useWeb3React } from '@web3-react/core'

const QRCodeWrapper = styled.div`
  text-align: center;
  border: 1px solid var(--mc-color-light-4);
  border-radius: 20px;
  padding: 32px 32px 18px;
  width: 300px;
`
const Heading = styled.div`
  margin-top: 24px;
  margin-bottom: 18px;
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
`
const TextBold = styled.div`
  font-weight: 600;
  display: inline;
`

const RefreshText = styled.div`
  margin-top: 10px;
  font-size: 12px;
  line-height: 14px;
  color: var(--mc-color-light-7);
`

interface IQrCodeViewProps {
  uri?: string
}

export const QrCodeView: FC<IQrCodeViewProps> = () => {
  const { activate, account } = useWeb3React()
  const [timeLeft, setTimeLeft] = useState(0)
  const timeLeftRef = useRef<NodeJS.Timeout | null>(null)

  const [uri, setUri] = useState<string>()
  const countdownTime = 60

  const loadQrCode = async () => {
    try {
      const qrConnector = new RoninConnector({
        kwargs: { supportedChainIds: SUPPORTED_CHAIN_IDS },
        isQr: true,
      })

      const mobileConnector = qrConnector.connector as RoninMobileConnector
      mobileConnector.on('display_uri', (uri: string) => {
        console.log(uri)
        setUri(uri)
      })
      await activate(qrConnector)
    } catch (e) {
      console.error('error')
    }
  }

  useEffect(() => {
    if (timeLeft === 0) {
      if (timeLeftRef.current) {
        clearInterval(timeLeftRef.current)
        timeLeftRef.current = null
      }
      loadQrCode()
      setTimeLeft(countdownTime)
      return
    }
    if (timeLeft < countdownTime || timeLeftRef.current) {
      return
    }
    timeLeftRef.current = setInterval(() => {
      setTimeLeft((preData) => preData - 1)
    }, 1000)
  }, [timeLeft])

  useEffect(() => {
    return () => {
      if (timeLeftRef.current) {
        clearInterval(timeLeftRef.current)
        timeLeftRef.current = null
      }
    }
  }, [])

  return (
    <div>
      {uri && (
        <QRCodeWrapper>
          <QRCodeCanvas
            value={uri}
            size={344}
            style={{
              borderRadius: '12px',
              padding: '20px',
              height: 200,
              width: 200,
              background: '#ffffff',
              boxShadow: '0 8px 30px rgba(222,230,241,.8)',
            }}
            imageSettings={{
              src: roninLogo,
              x: undefined,
              y: undefined,
              height: 60,
              width: 60,
              excavate: true,
            }}
          />
          <Heading>Login by QR Code</Heading>
          <div>
            Scan this with <TextBold>Ronin Mobile Wallet</TextBold> to login instantly
          </div>
          <RefreshText>Refreshes in {timeLeft} seconds</RefreshText>
        </QRCodeWrapper>
      )}
    </div>
  )
}
