import { Button, Spinner } from '@axieinfinity/mochi'
import { AlertTriangleIcon, FilledWalletIcon } from '@axieinfinity/mochi-icons'
import { AbstractConnector } from '@web3-react/abstract-connector'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import { AppContext } from 'index'
import { useContext, useEffect, useMemo } from 'react'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components'
import { NetworkContextName } from '../../constants/misc'
import useENSName from '../../hooks/useENSName'
import { useHasSocks } from '../../hooks/useSocksBalance'
import { useModalOpen, useSignInModalToggle, useWalletModalToggle } from '../../state/application/hooks'
import { isTransactionRecent, useAllTransactions } from '../../state/transactions/hooks'
import { TransactionDetails } from '../../state/transactions/reducer'
import { shortenAddress } from '../../utils'
import { ButtonSecondary } from '../Button'
import WalletModal from '../WalletModal'
import { ApplicationModal } from 'state/application/actions'
import SignInModal from 'components/SignInModal'

const Web3StatusGeneric = styled(ButtonSecondary)`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  align-items: center;
  padding: 0.5rem;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  :focus {
    outline: none;
  }
`
const ButtonConnectWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: var(--mc-space-inline-8x);
`

const ButtonConnect = styled(Button)`
  background-color: var(--mc-color-blue-1) !important;
  color: var(--mc-color-blue-5) !important;
`

const Web3StatusConnected = styled(Web3StatusGeneric)<{ pending?: boolean }>`
  height: 20px;
  font-size: 10px;
  margin-right: 8px;
  font-weight: 500;
  color: var(--mc-color-light-7);
  background-color: var(--mc-color-white);

  border: none;
  box-shadow: none;
  :hover,
  :focus {
    border: none;
    box-shadow: none;
    :focus {
      border: none;
      box-shadow: none;
    }
  }
`

const FilledWalletIconWrapperSmall = styled.div`
  height: 24px;
  display: flex;
  align-items: center;
  @media (min-width: 768px) {
    display: none;
  }
`
const FilledWalletIconWrapperMedium = styled.div`
  height: 24px;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    display: none;
  }
`

// we want the latest one to come first, so return negative if a is after b
export function newTransactionsFirst(a: TransactionDetails, b: TransactionDetails) {
  return b.addedTime - a.addedTime
}

function Sock() {
  return (
    <span role="img" aria-label={`has socks emoji`} style={{ marginTop: -4, marginBottom: -4 }}>
      🧦
    </span>
  )
}

// eslint-disable-next-line react/prop-types
function StatusIcon({ connector }: { connector: AbstractConnector }) {
  return null
}

function Web3StatusInner() {
  const { account, connector, error } = useWeb3React()
  const toggleWalletModal = useWalletModalToggle()
  const toggleSignInModal = useSignInModalToggle()

  const { ENSName } = useENSName(account ?? undefined)
  const { pendingLogin } = useContext(AppContext)
  const allTransactions = useAllTransactions()

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])

  const pending = sortedRecentTransactions.filter((tx) => !tx.receipt).map((tx) => tx.hash)

  const hasPendingTransactions = !!pending.length
  const hasSocks = useHasSocks()

  if (account || (account && error && error instanceof UnsupportedChainIdError)) {
    return (
      <Web3StatusConnected id="web3-status-connected" onClick={toggleWalletModal} pending={hasPendingTransactions}>
        <>
          {hasSocks ? <Sock /> : null}
          {ENSName || shortenAddress(account, undefined, true).toLowerCase()}
        </>
        {!hasPendingTransactions && connector && <StatusIcon connector={connector} />}
      </Web3StatusConnected>
    )
  } else if (error && !(error instanceof UnsupportedChainIdError)) {
    return (
      <Button size="small" intent="danger" onClick={toggleWalletModal} leftIcon={AlertTriangleIcon}>
        Error
      </Button>
    )
  } else {
    return (
      <ButtonConnect
        size="small"
        disabled={pendingLogin}
        minimal
        id="connect-wallet"
        onClick={() => {
          !account && toggleSignInModal()
          account && toggleWalletModal()
        }}
      >
        <ButtonConnectWrapper>
          {pendingLogin && <Spinner intent="primary" size="small" />}
          <FilledWalletIconWrapperSmall>
            <FilledWalletIcon size={16} />
          </FilledWalletIconWrapperSmall>
          <FilledWalletIconWrapperMedium>
            <FilledWalletIcon size={20} />
          </FilledWalletIconWrapperMedium>
          {isMobile ? 'Connect' : 'Connect Ronin Wallet'}
        </ButtonConnectWrapper>
      </ButtonConnect>
    )
  }
}

export default function Web3Status() {
  const signInModalOpened = useModalOpen(ApplicationModal.SIGN_IN)
  const { active, account } = useWeb3React()
  const contextNetwork = useWeb3React(NetworkContextName)

  const { ENSName } = useENSName(account ?? undefined)

  const allTransactions = useAllTransactions()

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])

  const pending = sortedRecentTransactions.filter((tx) => !tx.receipt).map((tx) => tx.hash)
  const confirmed = sortedRecentTransactions.filter((tx) => tx.receipt).map((tx) => tx.hash)

  if (!contextNetwork.active && !active) {
    return null
  }

  return (
    <>
      <Web3StatusInner />
      <WalletModal ENSName={ENSName ?? undefined} pendingTransactions={pending} confirmedTransactions={confirmed} />
      <SignInModal />
    </>
  )
}
