import { Button } from '@axieinfinity/mochi'
import { TransactionResponse } from '@ethersproject/abstract-provider'
import { Contract } from '@ethersproject/contracts'
import { Currency, CurrencyAmount, Token } from '@uniswap/sdk-core'
import Modal from 'components/Modal'
import TransactionConfirmationModal from 'components/TransactionConfirmationModal'
import { BigNumber } from 'ethers'
import useToggle from 'hooks/useToggle'
import isNaN from 'lodash/isNaN'
import isNil from 'lodash/isNil'
import noop from 'lodash/noop'
import { useEffect, useState } from 'react'
import { useTransactionAdder } from 'state/transactions/hooks'
import styled from 'styled-components'
import { toWei } from 'web3-utils'
import { CloseIcon } from '../../theme'
import { RowBetween } from '../Row'
import { TokenInputBox } from './TokenInputBox'

const FarmStakeButton = styled(Button)`
  margin-top: 24px;
  width: 100%;
`
export enum StakeDialogAction {
  Stake = 'Stake',
  Unstake = 'Unstake',
}

const ModalContentWrapper = styled.div`
  width: 100%;
  padding: 24px;
`
const TokenAmount = styled.h5`
  font-weight: 600;
`

const DialogDivider = styled.div`
  border-top-width: 1px;
  border-color: var(--mc-color-light-3);
  margin-left: 16px -24px 16px -24px;
`
interface IFarmStakeDialogProps {
  isOpen: boolean
  onClose: () => void
  maxAmount?: CurrencyAmount<Token>
  action: StakeDialogAction
  firstTokenInfo?: Currency
  secondTokenInfo?: Currency
  stakingPoolContract?: Contract
  addPendingTx?: (txHash: string) => void
}

export const FarmStakeDialog = ({
  isOpen,
  onClose,
  maxAmount,
  action,
  firstTokenInfo,
  secondTokenInfo,
  stakingPoolContract,
  addPendingTx = noop,
}: IFarmStakeDialogProps) => {
  const addTransaction = useTransactionAdder()

  const [amount, setAmount] = useState<string>('0')
  const [inputDirty, setInputDirty] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)

  const [txSubmitOpen, toggleTxSubmit] = useToggle(false)
  const [submitedTxHash, setSubmitedTxHash] = useState<string>()

  useEffect(() => {
    setAmount('0')
    setInputDirty(false)
  }, [action, isOpen])

  const handleChangeAmount = (amount: string) => {
    setAmount(amount)
    setInputDirty(true)
  }

  const handleActionButtonClick = async () => {
    if (isNil(stakingPoolContract)) {
      return
    }

    setLoading(true)
    try {
      const amountValue = toWei(amount, 'ether')
      const amountBN = BigNumber.from(amountValue)

      let response: TransactionResponse | undefined

      if (action === StakeDialogAction.Stake) {
        const estimateGas = await stakingPoolContract.estimateGas.stake(amountBN)
        const safeGas = Math.round(estimateGas.toNumber() * 1.5)

        response = await stakingPoolContract.stake(amountBN, { gasLimit: safeGas })
      } else if (action === StakeDialogAction.Unstake) {
        const estimateGas = await stakingPoolContract.estimateGas.unstake(amountBN)
        const safeGas = Math.round(estimateGas.toNumber() * 1.5)

        response = await stakingPoolContract.unstake(amountBN, { gasLimit: safeGas })
      }

      if (!isNil(response)) {
        setSubmitedTxHash(response.hash)
        toggleTxSubmit()
        addPendingTx(response.hash)
        addTransaction(response, {
          summary: `${action} ${firstTokenInfo?.symbol ?? `UNKN`}-${secondTokenInfo?.symbol ?? `UNKN`} LP`,
        })
      }
    } catch (error) {
      console.error('handleActionButtonClick', error)
    } finally {
      onClose()
      setLoading(false)
    }
  }

  const handleDismissSubmitTxModal = () => {
    toggleTxSubmit()
    setSubmitedTxHash(undefined)
  }

  const parseAmount = parseFloat(amount)
  const validAmount = !isNaN(parseAmount) && parseAmount > 0

  let insufficient = false
  if (inputDirty && !isNil(maxAmount) && !isNaN(parseAmount)) {
    // HACK: Use `toWei` here is bad logic
    // => Should convert from string to CurrencyAmount object base on decimals
    // `toWei` only work for 18 decimals tokens - LP tokens

    try {
      const amountAsWei = toWei(amount)
      const currentAmount = CurrencyAmount.fromRawAmount(maxAmount.currency, amountAsWei)
      insufficient = maxAmount.lessThan(currentAmount)
    } catch (error) {
      insufficient = true
    }
  }

  return (
    <>
      <Modal isOpen={isOpen} onDismiss={onClose} maxWidth={460}>
        <ModalContentWrapper>
          <RowBetween>
            <TokenAmount>{`${action} LP tokens`}</TokenAmount>
            <CloseIcon onClick={onClose} size="20px" />
          </RowBetween>

          <DialogDivider />

          <TokenInputBox
            amountInEth={amount}
            insufficientError={insufficient}
            maxAmount={maxAmount}
            onChangeAmount={handleChangeAmount}
            firstTokenInfo={firstTokenInfo}
            secondTokenInfo={secondTokenInfo}
          />

          <FarmStakeButton
            disabled={loading || insufficient || !validAmount}
            intent={action === StakeDialogAction.Stake ? 'primary' : 'danger'}
            onClick={handleActionButtonClick}
          >
            {insufficient ? 'Insufficient balance' : action}
          </FarmStakeButton>
        </ModalContentWrapper>
      </Modal>
      <TransactionConfirmationModal
        isOpen={txSubmitOpen}
        onDismiss={handleDismissSubmitTxModal}
        hash={submitedTxHash}
        attemptingTxn={false}
        pendingText=""
        content={() => {
          return null
        }}
      />
    </>
  )
}
