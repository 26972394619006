import { DEFAULT_CHAIN_ID, SupportedChainId } from 'constants/chains'
import { useActiveWeb3React } from 'hooks/web3'
import styled from 'styled-components/macro'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-item: center;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  border-top: 3px solid #f69400;
  z-index: 4;
`

const Label = styled.div`
  margin-top: -3px;
  padding: 2px 8px;
  background: #f69400;
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  text-shadow: 0px 1px 1px #d68e00;
  color: #fff;
  border-radius: 0px 0px 4px 4px;
  user-select: none;
`

const CHAIN_LABEL: Record<SupportedChainId, string> = {
  [SupportedChainId.RONIN_MAINNET]: 'Ronin Mainnet',
  [SupportedChainId.RONIN_TESTNET]: 'Testnet',
  [SupportedChainId.RONIN_DEVNET]: 'Devnet',
}

export const TestnetIndicator = () => {
  const { chainId } = useActiveWeb3React()

  if (chainId === DEFAULT_CHAIN_ID) return null

  return (
    <Wrapper>
      <Label>{CHAIN_LABEL[chainId]}</Label>
    </Wrapper>
  )
}
