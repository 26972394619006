import { RoninMobileConnector } from '@axieinfinity/connect/dist/RoninMobileConnector'

export const createMobileConnector = () => {
  const connector = new RoninMobileConnector({
    bridgeUrl: 'https://bridge.walletconnect.org',
    rpcMap: {
      [2020]: 'https://api.roninchain.com/rpc',
      [2021]: 'https://saigon-testnet.roninchain.com/rpc',
    },
  })
  return connector
}
