import { Web3Provider } from '@ethersproject/providers'
import { useWeb3React as useWeb3ReactCore } from '@web3-react/core'
import { Web3ReactContextInterface } from '@web3-react/core/dist/types'
import { DEFAULT_CHAIN_ID, SupportedChainId } from 'constants/chains'
import { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { roninWallet } from '../connectors'
import { NetworkContextName } from '../constants/misc'

interface IActiveWeb3 extends Web3ReactContextInterface<Web3Provider> {
  chainId: SupportedChainId
}

export function useActiveWeb3React(): IActiveWeb3 {
  const context = useWeb3ReactCore<Web3Provider>()
  const contextNetwork = useWeb3ReactCore<Web3Provider>(NetworkContextName)

  const activedContext = context.active ? context : contextNetwork

  return {
    ...activedContext,
    chainId: activedContext.chainId ?? DEFAULT_CHAIN_ID,
  }
}

// try to auto-connect to Ronin Wallet when mount
export function useEagerConnect() {
  const { activate, active } = useWeb3ReactCore() // specifically using useWeb3ReactCore because of what this hook does
  const [tried, setTried] = useState(false)

  useEffect(() => {
    // if on mobile, check walletconnect info before connect
    if (isMobile && !window.ronin && !localStorage.getItem('walletconnect')) {
      setTried(true)
      return
    }

    activate(roninWallet, undefined, true).catch(() => {
      setTried(true)
    })
  }, [activate]) // intentionally only running on mount (make sure it's only mounted once :))

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (active) {
      setTried(true)
    }
  }, [active])

  return tried
}

/**
 * Use for network and injected - logs user in
 * and out after checking what network theyre on
 */
export function useInactiveListener(suppress = false) {
  const { active, error, activate, connector } = useWeb3ReactCore() // specifically using useWeb3React because of what this hook does

  useEffect(() => {
    const walletExtProvider = window.ronin

    if (walletExtProvider && !active && !error && !suppress) {
      // NOTE: retry connection when login to Ronin Wallet Extension or change account

      const handleAccountsChanged = () => {
        activate(roninWallet, undefined, true).catch((error) => {
          console.error('Failed to activate after accounts changed', error)
        })
      }

      walletExtProvider.roninEvent?.addEventListener('account_changed', handleAccountsChanged)

      return () => {
        walletExtProvider.roninEvent?.removeEventListener('account_changed', handleAccountsChanged)
        connector?.removeAllListeners()
      }
    }

    return undefined
  }, [active, error, suppress, activate])
}
