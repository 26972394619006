// a list of tokens by chain
import { Currency, Token } from '@uniswap/sdk-core'
import { SupportedChainId } from './chains'
import {
  AXS,
  AXS_TESTNET,
  ExtendedEther,
  SLP,
  SLP_TESTNET,
  USDC,
  USDC_TESTNET,
  WETH,
  WETH9_EXTENDED,
  WETH_TESTNET,
  WRON,
  WRON_TESTNET,
} from './tokens'

type ChainTokenList = {
  readonly [chainId: number]: Token[]
}

type ChainCurrencyList = {
  readonly [chainId: number]: Currency[]
}

const WETH_ONLY: ChainTokenList = {
  [SupportedChainId.RONIN_TESTNET]: [WETH9_EXTENDED[SupportedChainId.RONIN_TESTNET]],
  [SupportedChainId.RONIN_MAINNET]: [WETH9_EXTENDED[SupportedChainId.RONIN_MAINNET]],
}
// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WETH_ONLY,
}
export const ADDITIONAL_BASES: { [chainId: number]: { [tokenAddress: string]: Token[] } } = {
  [SupportedChainId.RONIN_TESTNET]: {
    [WRON_TESTNET.address]: [WETH_TESTNET],
    [AXS_TESTNET.address]: [WETH_TESTNET],
    [SLP_TESTNET.address]: [WETH_TESTNET],
    [USDC_TESTNET.address]: [WETH_TESTNET],
  },
  [SupportedChainId.RONIN_MAINNET]: {
    [WRON.address]: [WETH],
    [AXS.address]: [WETH],
    [SLP.address]: [WETH],
    [USDC.address]: [WETH],
  },
}
/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId: number]: { [tokenAddress: string]: Token[] } } = {}

/**
 * Shows up in the currency select for swap and add liquidity
 */
export const COMMON_BASES: ChainCurrencyList = {
  [SupportedChainId.RONIN_TESTNET]: [
    ExtendedEther.onChain(SupportedChainId.RONIN_TESTNET),
    WETH9_EXTENDED[SupportedChainId.RONIN_TESTNET],
  ],
  [SupportedChainId.RONIN_MAINNET]: [
    ExtendedEther.onChain(SupportedChainId.RONIN_MAINNET),
    WETH9_EXTENDED[SupportedChainId.RONIN_MAINNET],
  ],
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WETH_ONLY,
  [SupportedChainId.RONIN_MAINNET]: [WRON, WETH, SLP, AXS],
  [SupportedChainId.RONIN_TESTNET]: [WRON_TESTNET, WETH_TESTNET, SLP_TESTNET, AXS_TESTNET],
}
export const PINNED_PAIRS: { readonly [chainId: number]: [Token, Token][] } = {}
