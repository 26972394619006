import { DEFAULT_CHAIN_ID, SupportedChainId } from '../constants/chains'

export enum ExplorerDataType {
  TRANSACTION = 'transaction',
  TOKEN = 'token',
  ADDRESS = 'address',
  BLOCK = 'block',
}

export const EXPLORER_URL: Record<SupportedChainId, string> = {
  [SupportedChainId.RONIN_MAINNET]: 'https://explorer.roninchain.com',
  [SupportedChainId.RONIN_TESTNET]: 'https://saigon-explorer.roninchain.com',
  [SupportedChainId.RONIN_DEVNET]: 'https://hcm-explorer.skymavis.one',
}

/**
 * Return the explorer link for the given data and data type
 * @param chainId the ID of the chain for which to return the data
 * @param data the data to return a link for
 * @param type the type of the data
 */
export function getExplorerLink(chainId: number, data: string, type: ExplorerDataType): string {
  const explorerUrl = EXPLORER_URL[chainId as SupportedChainId] ?? EXPLORER_URL[DEFAULT_CHAIN_ID]

  switch (type) {
    case ExplorerDataType.TRANSACTION:
      return `${explorerUrl}/tx/${data}`
    case ExplorerDataType.ADDRESS:
      return `${explorerUrl}/address/${data.replace('0x', 'ronin:')}`
    case ExplorerDataType.BLOCK:
      return `${explorerUrl}/block/${data.replace('0x', 'ronin:')}`
    default:
      return `${explorerUrl}`
  }
}
