import { createRoninMobileConnector } from '@axieinfinity/connect'
import Modal from 'components/Modal'
import React, { useEffect, useState } from 'react'
import { ApplicationModal } from 'state/application/actions'
import { useModalOpen, useSignInModalToggle, useWalletModalToggle } from 'state/application/hooks'
import styled from 'styled-components'
import { useWeb3React } from '@web3-react/core'
import { Button } from '@axieinfinity/mochi'
import { QrCodeView } from 'components/QRCodeView/QrCodeView'
import { RoninConnector } from 'connectors/RoninConnector'
import { SUPPORTED_CHAIN_IDS } from 'connectors'

const Wrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  margin: 0;
  padding: 16px 0px;
  width: 100%;
`
const ModalContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  align-items: center;
  justify-content: center;
`
const RoninConnectButton = styled(Button)`
  background-color: var(--mc-color-blue-1) !important;
  color: var(--mc-color-blue-5) !important;
  margin-top: 20px;
  width: 100%;
`

export default function SignInModal() {
  const { account, activate } = useWeb3React()
  const modalOpen = useModalOpen(ApplicationModal.SIGN_IN)
  const toggleSignInModal = useSignInModalToggle()
  const toggleWalletModal = useWalletModalToggle()

  function getModalContent() {
    return (
      <ModalContentColumn>
        <ModalContentColumn>
          <QrCodeView />
        </ModalContentColumn>
        <ModalContentColumn>
          <RoninConnectButton
            onClick={() => {
              toggleWalletModal()
            }}
            minimal
          >
            Login with Ronin Wallet extension
          </RoninConnectButton>
        </ModalContentColumn>

        {/* <button onClick={onLoginWithRoninMobile}>new ronin mobile connector</button> */}
      </ModalContentColumn>
    )
  }

  return (
    <Modal isOpen={modalOpen && !account} onDismiss={toggleSignInModal}>
      <Wrapper>{getModalContent()}</Wrapper>
    </Modal>
  )
}
