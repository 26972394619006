import { BaseConnector, createRoninConnector, RoninNotFoundError, RoninNotUnlockedError } from '@axieinfinity/connect'
import { RoninMobileConnector } from '@axieinfinity/connect/dist/RoninMobileConnector'
import { ExternalProvider } from '@ethersproject/providers'
import { AbstractConnector } from '@web3-react/abstract-connector'
import { AbstractConnectorArguments, ConnectorUpdate } from '@web3-react/types'
import { isMobile } from 'react-device-detect'
import warning from 'tiny-warning'
import { createMobileConnector } from './CreateMobileConnector'

export class UserRejectedRequestError extends Error {
  public constructor() {
    super()
    this.name = this.constructor.name
    this.message = 'The user rejected the request.'
  }
}

export const checkInAppBrowser = () => {
  if (typeof window !== 'undefined') {
    return !!window.isWalletApp && window.ronin !== undefined
  } else {
    return false
  }
}

type Options = {
  kwargs: AbstractConnectorArguments
  isQr: boolean
}

export class RoninConnector extends AbstractConnector {
  connector: BaseConnector
  isQr: boolean

  constructor(options: Options) {
    const { kwargs, isQr } = options
    super(kwargs)

    this.handleNetworkChanged = this.handleNetworkChanged.bind(this)
    this.handleChainChanged = this.handleChainChanged.bind(this)
    this.handleAccountsChanged = this.handleAccountsChanged.bind(this)
    this.handleClose = this.handleClose.bind(this)

    this.isQr = isQr
    this.connector = this.initRoninConnector()
  }

  private initRoninConnector() {
    const isInApp = checkInAppBrowser()
    let walletConnector
    if (this.isQr || (isMobile && !isInApp)) {
      walletConnector = createMobileConnector()
    } else {
      walletConnector = createRoninConnector()
    }
    if (!this.isQr || (isMobile && !isInApp)) {
      ;(walletConnector as any).on('display_uri', (uri: string) => {
        const encoded = encodeURIComponent(uri)
        const url = `https://wallet.roninchain.com/auth-connect?uri=${encoded}`
        window.open(url, '_self')
      })
    }

    return walletConnector
  }

  private handleChainChanged(chainId: string | number): void {
    this.emitUpdate({ chainId, provider: this.composeProvider() })
  }

  public handleAccountsChanged(accounts: string[]): void {
    if (accounts.length === 0) {
      this.emitDeactivate()
    } else {
      this.emitUpdate({ account: accounts[0] })
    }
  }

  private handleClose(code: number, reason: string): void {
    this.emitDeactivate()
  }

  private handleNetworkChanged(networkId: string | number): void {
    this.emitUpdate({ chainId: networkId, provider: this.composeProvider() })
  }

  public async activate(): Promise<ConnectorUpdate> {
    const walletConnector = this.connector

    try {
      await walletConnector.connect()
      const walletAccounts = await walletConnector.getAccounts()
      const currentAccount = walletAccounts[0]
      const walletHexChainId = await walletConnector.getChainId()

      if (!currentAccount || !walletHexChainId) {
        throw new RoninNotUnlockedError()
      }

      walletConnector.on('account_changed', (nextAccount) => this.handleAccountsChanged([nextAccount]))
      walletConnector.on('chain_id_changed', this.handleChainChanged)

      // try {
      //   const provider = walletConnector.provider as any
      //   provider.on('accountsChanged', (nextAccount: string) => this.handleAccountsChanged([nextAccount]))
      //   provider.on('chainChanged', this.handleChainChanged)
      // } catch (_) {}

      return { provider: this.composeProvider(), account: currentAccount, chainId: walletHexChainId }
    } catch (error) {
      if ((error as any).code === 4001) {
        throw new UserRejectedRequestError()
      }
    }

    return { provider: this.composeProvider() }
  }

  public async getProvider(): Promise<any> {
    return this.composeProvider()
  }

  public async getChainId(): Promise<number | string> {
    if (!this.connector || !this.connector.provider) {
      throw new RoninNotFoundError()
    }

    let chainId: number | undefined
    try {
      chainId = await this.connector.getChainId()
    } catch {
      warning(false, 'eth_chainId was unsuccessful, falling back to net_version')
    }

    return chainId || ''
  }

  public async getAccount(): Promise<null | string> {
    if (!this.connector || !this.connector.provider) {
      throw new RoninNotFoundError()
    }

    let account = null
    try {
      account = (await this.connector.getAccounts())[0]
    } catch {
      warning(false, 'eth_accounts was unsuccessful, falling back to enable')
    }

    return account
  }

  public async deactivate() {
    console.log('Deactive ')
    this.connector.removeAllListeners()

    if (isMobile && !window.ronin) {
      const mobileConnector = this.connector as any
      mobileConnector.disconnectAndKillSession()
    }
  }

  public async isAuthorized(): Promise<boolean> {
    if (!this.connector || !this.connector.provider) {
      return false
    }

    try {
      const accounts = await this.connector.getAccounts()
      return accounts.length > 0
    } catch {
      return false
    }
  }

  private openMobileWalletAfterSend(payload: any) {
    const isInApp = checkInAppBrowser()

    if (isMobile && !isInApp) {
      if (payload.method === 'eth_accounts') {
        // this is a read method, no action required
        return
      }

      const walletMethods = [
        'eth_accounts',
        'eth_sendTransaction',
        'eth_signTransaction',
        'eth_sign',
        'personal_sign',
        'eth_signTypedData',
      ]

      if (walletMethods.includes(payload.method)) {
        window.open('https://wallet.roninchain.com', '_self')
      }
    }
  }

  private composeProvider(): ExternalProvider {
    return {
      sendAsync: (payload: any, callback) => {
        if (!this.connector || !this.connector.provider) {
          if (payload.method === 'eth_accounts') {
            callback(null, {
              id: payload.id,
              result: [],
              jsonrpc: payload.jsonrpc,
            })
            return
          }

          const error = new RoninNotFoundError()
          this.emit('wallet_call_error', error)
          callback(error, undefined)
          return
        }

        this.connector.provider.sendAsync(payload, callback)
        this.openMobileWalletAfterSend(payload)
        return
      },
    }
  }
}
