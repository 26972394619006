import { isAddress } from 'ethers/lib/utils'

export const roninPrefix = 'ronin:'

export const formatRoninAddress = (address: string) => {
  if (isAddress(address)) {
    return `${roninPrefix}${address.split('0x')[1]}`
  } else {
    console.error('Invalid address')
    return address
  }
}
