import { Button, Spinner } from '@axieinfinity/mochi'
import { CircleArrowRightIcon, CrossIcon, PlusIcon } from '@axieinfinity/mochi-icons'
import { Currency } from '@uniswap/sdk-core'
import sidechainImg from 'assets/images/sidechain.png'
import CurrencyLogo from 'components/CurrencyLogo'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import useAddTokenToMetamask from 'hooks/useAddTokenToMetamask'
import { ReactNode, useContext } from 'react'
import { Text } from 'rebass'
import styled, { ThemeContext } from 'styled-components'
import { openInAppBrowser } from 'utils/openInAppBrowser'
import { useActiveWeb3React } from '../../hooks/web3'
import { ExternalLink } from '../../theme'
import { CloseIcon } from '../../theme/components'
import { ExplorerDataType, getExplorerLink } from '../../utils/getExplorerLink'
import { AutoColumn, ColumnCenter } from '../Column'
import Modal from '../Modal'
import { RowBetween } from '../Row'

const Wrapper = styled.div`
  width: 100%;
  overflow: auto;
  padding: ${({ padding = '1rem' }: { padding?: string }) => padding};
`
const Section = styled(AutoColumn)<{ inline?: boolean }>`
  padding: ${({ inline }) => (inline ? '0' : '0')};
`

const BottomSection = styled(Section)`
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`

const ConfirmedIcon = styled(ColumnCenter)<{ inline?: boolean }>`
  padding: ${({ inline }) => (inline ? '20px 0' : '60px 0;')};
`

const StyledLogo = styled.img`
  height: 16px;
  width: 16px;
  margin-left: 6px;
`

const CircleArrowRightIconWrapper = styled.div`
  margin-right: 8px;
  margin-left: 8px;
`
const PlusIconWrapper = styled.div`
  margin-right: 8px;
  margin-left: 8px;
`
const CrossIconWrapper = styled.div`
  cursor: pointer;
`
const CrossIconWrapperAbsolute = styled(CrossIconWrapper)`
  position: absolute;
  top: 20px;
  right: 20px;
`
//"mx-auto mt-12 mb-24"
const SpinnerWrapper = styled.div`
  margin-right: auto;
  margin-left: auto;
  margin-top: 12px;
  margin-bottom: 24px;
`
const ButtonTryAgain = styled(Button)`
  margin-right: auto;
  margin-left: auto;
  display: block;
`
const ButtonFullWidth = styled(Button)`
  width: 100%;
`
const ButtonHalfWidth = styled(Button)`
  width: 50%;
`
const CurrencyLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: var(--mc-space-stack-8x);
`
const TransactionSubmittedContainer = styled.div`
  padding-right: var(--mc-space-inline-16x);
  padding-left: var(--mc-space-inline-16x);
`
const SideChainImg = styled.img`
  margin-left: auto;
  margin-right: auto;
`
const HeaderTransactionSubmitted = styled.div`
  margin-bottom: var(--mc-space-stack-24x);
  margin-top: var(--mc-space-stack-32x);
`
const MainHeader = styled.div`
  font-weight: var(--mc-font-weight-semi-bold);
  text-align: center;
  font-size: var(--mc-font-size-large);
  line-height: var(--mc-line-height-x-large);
`
const SubHeader = styled.div`
  text-align: center;
  margin-top: var(--mc-space-stack-8x);
  line-height: var(--mc-line-height-medium);
`
const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: var(--mc-space-stack-12x);
`
const ButtonOpenExplorer = styled.div`
  margin-left: var(--mc-space-inline-16x);
  text-decoration: none;
  flex: 1 1 0%;
  width: 50%;
`
const HeaderConfirmModal = styled.div`
  position: relative;
  padding: var(--mc-space-inset-24x);
  padding-bottom: var(--mc-space-stack-16x);
  border-bottom-width: var(--mc-space-stack-x);
  border-color: var(--mc-color-light-3);
  width: 100%;
`
const TitleConfirmModal = styled.span`
  font-weight: var(--mc-font-weight-semi-bold);
  font-size: var(--mc-font-size-large);
  line-height: var(--mc-line-height-x-large);
`
const ContainerTransactionError = styled(Wrapper)`
  /* padding: 28px var(--mc-space-inline-32x); */
  position: relative;
`
const CloseIconWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`
const MainContent = styled(AutoColumn)`
  margin-bottom: var(--mc-space-stack-24x);
`
const SideChainImgWrapper = styled.div`
  overflow: hidden;
  margin-top: 28px;
  width: 182px;
  height: 104px;
`
const TitleTransactionError = styled(Text)`
  line-height: var(--mc-line-height-x-large);
  color: var(--mc-color-light-9);
  text-align: center;
  margin-bottom: var(--mc-space-stack-8x);
`
const MessageTransactionError = styled(Text)`
  text-align: center;
  width: 100%;
  word-break: break-word;
  line-height: 21px;
`
const ButtonTryAgainWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
export function ConfirmationPendingContent({
  onDismiss,
  trade,
  pendingText,
  inline,
  hasRelatedIcon = true,
  isSupplying,
}: {
  onDismiss: () => void
  pendingText: ReactNode
  trade?: { tokenA?: Currency; tokenB?: Currency }
  isSupplying?: boolean
  hasRelatedIcon?: boolean
  inline?: boolean // not in modal
}) {
  return (
    <Wrapper>
      <AutoColumn gap="md">
        {!inline && (
          <RowBetween>
            <div />
            <CloseIcon onClick={onDismiss} color="var(--mc-color-light-7)" />
          </RowBetween>
        )}
        {/* <ConfirmedIcon inline={inline}>
          <CustomLightSpinner src={Circle} alt="loader" size={inline ? '40px' : '90px'} />
        </ConfirmedIcon> */}
        <CurrencyLogoWrapper>
          {hasRelatedIcon && (
            <>
              <CurrencyLogo currency={trade?.tokenA} size="64px" />
              {!isSupplying ? (
                <CircleArrowRightIconWrapper>
                  <CircleArrowRightIcon size={24} color="var(--mc-color-light-7)" />
                </CircleArrowRightIconWrapper>
              ) : (
                <PlusIconWrapper>
                  <PlusIcon size={24} color="var(--mc-color-light-7)" />
                </PlusIconWrapper>
              )}
              <CurrencyLogo currency={trade?.tokenB} size="64px" />
            </>
          )}
          {!hasRelatedIcon && (
            <>
              <DoubleCurrencyLogo currency0={trade?.tokenA} currency1={trade?.tokenB} size={64} />
            </>
          )}
        </CurrencyLogoWrapper>
        <AutoColumn gap="12px" justify={'center'}>
          <Text fontWeight={600} fontSize={20} color="var(--mc-color-light-9)" textAlign="center">
            {pendingText}
          </Text>
        </AutoColumn>
        <AutoColumn gap="12px" justify={'center'}>
          {/* <Text fontWeight={500} fontSize={20} textAlign="center">
            <Trans>Waiting For Confirmation</Trans>
          </Text> */}
          <Text fontSize={14} color="var(--mc-color-light-7)" textAlign="center" marginBottom={12}>
            {/* <Trans>Confirm this transaction in your wallet.</Trans> */}
            Confirm this transaction in your wallet.
          </Text>
        </AutoColumn>
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      </AutoColumn>
    </Wrapper>
  )
}

export function TransactionSubmittedContent({
  onDismiss,
  chainId,
  hash,
  currencyToAdd,
  inline,
}: {
  onDismiss: () => void
  hash: string | undefined
  chainId: number
  currencyToAdd?: Currency | undefined
  inline?: boolean // not in modal
}) {
  const theme = useContext(ThemeContext)

  const { library } = useActiveWeb3React()

  const { addToken, success } = useAddTokenToMetamask(currencyToAdd)

  return (
    <Wrapper>
      <Section inline={inline}>
        {!inline && (
          <RowBetween>
            <div />
            <CrossIconWrapper>
              <CrossIcon size={24} onClick={onDismiss} color="var(--mc-color-light-7)" />
            </CrossIconWrapper>
          </RowBetween>
        )}
        {/* <ConfirmedIcon inline={inline}>
          <ArrowUpCircle strokeWidth={0.5} size={inline ? '40px' : '90px'} color={theme.primary1} />
        </ConfirmedIcon> */}
        <TransactionSubmittedContainer>
          <SideChainImg src={sidechainImg} alt="" width={182} />
          <HeaderTransactionSubmitted>
            <MainHeader>
              {/* <Trans>Transaction submitted</Trans> */}
              Transaction submitted
            </MainHeader>
            <SubHeader>
              {/* <Trans>This may take a few moments to process!</Trans> */}
              This may take a few moments to process!
            </SubHeader>
          </HeaderTransactionSubmitted>
          <AutoColumn gap="12px" justify={'center'}>
            {/* <Text fontWeight={500} fontSize={20} textAlign="center"> */}
            {/* <span className="font-semibold text-20 leading-28 -mt-48">
            <Trans>Transaction submitted</Trans>
          </span>
          <span>
            <Trans>This may take a few moment to process!</Trans>
          </span> */}
            {/* </Text> */}
            {/* {currencyToAdd && library?.provider?.isMetaMask && (
              <ButtonLight mt="12px" padding="6px 12px" width="fit-content" onClick={addToken}>
                {!success ? (
                  <RowFixed>
                    <Trans>
                      Add {currencyToAdd.symbol} to Metamask <StyledLogo src={MetaMaskLogo} />
                    </Trans>
                  </RowFixed>
                ) : (
                  <RowFixed>
                    <Trans>Added {currencyToAdd.symbol} </Trans>
                    <CheckCircle size={'16px'} stroke={theme.green1} style={{ marginLeft: '6px' }} />
                  </RowFixed>
                )}
              </ButtonLight>
            )} */}
            <ButtonContainer>
              {/* <ButtonPrimary onClick={onDismiss} style={{ margin: '20px 0 0 0' }}> */}
              <ButtonHalfWidth intent="primary" onClick={onDismiss}>
                {/* <Text fontWeight={500} fontSize={20}> */}
                {/* {inline ? <Trans>Return</Trans> : <Trans>Close</Trans>} */}
                {inline ? 'Return' : 'Close'}
                {/* </Text> */}
              </ButtonHalfWidth>
              {/* </ButtonPrimary> */}
              {chainId && hash && (
                <ButtonOpenExplorer>
                  {window.isWalletApp ? (
                    <ButtonFullWidth
                      intent="secondary"
                      onClick={() =>
                        openInAppBrowser(getExplorerLink(chainId, hash, ExplorerDataType.TRANSACTION), 'Ronin Explorer')
                      }
                    >
                      {/*<Trans>*/}View on Explorer{/*</Trans>*/}
                    </ButtonFullWidth>
                  ) : (
                    <ExternalLink
                      href={getExplorerLink(chainId, hash, ExplorerDataType.TRANSACTION)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <ButtonFullWidth intent="secondary">
                        {/*<Trans>*/}View on Explorer{/*</Trans>*/}
                      </ButtonFullWidth>
                    </ExternalLink>
                  )}
                </ButtonOpenExplorer>
              )}
            </ButtonContainer>
          </AutoColumn>
        </TransactionSubmittedContainer>
      </Section>
    </Wrapper>
  )
}

export function ConfirmationModalContent({
  title,
  bottomContent,
  onDismiss,
  topContent,
  bottomGap = '12px',
  padding,
  hasHeaderLine,
}: {
  title: ReactNode
  onDismiss: () => void
  topContent: () => ReactNode
  bottomContent?: () => ReactNode | undefined
  bottomGap?: string
  padding?: number | number
  hasHeaderLine?: boolean
}) {
  return (
    <Wrapper style={{ padding }}>
      <Section>
        {!hasHeaderLine && (
          <RowBetween>
            <Text fontWeight={500} fontSize={16}>
              {title}
            </Text>
            <CrossIconWrapper>
              <CrossIcon size={24} onClick={onDismiss} />
            </CrossIconWrapper>
          </RowBetween>
        )}
        {hasHeaderLine && (
          <HeaderConfirmModal>
            <TitleConfirmModal>{title}</TitleConfirmModal>
            <CrossIconWrapperAbsolute>
              <CrossIcon size={24} onClick={onDismiss} />
            </CrossIconWrapperAbsolute>
          </HeaderConfirmModal>
        )}
        {topContent()}
      </Section>
      {bottomContent && <BottomSection gap={bottomGap}>{bottomContent()}</BottomSection>}
    </Wrapper>
  )
}

export function TransactionErrorContent({
  message,
  onDismiss,
}: {
  message: { title: string; message: string }
  onDismiss: () => void
}) {
  // const theme = useContext(ThemeContext)
  return (
    <ContainerTransactionError>
      <CloseIconWrapper>
        <CloseIcon onClick={onDismiss} />
      </CloseIconWrapper>
      <Section>
        {/* <RowBetween>
          <Text fontWeight={500} fontSize={20}>
            <Trans>Error</Trans>
          </Text>
          <CloseIcon onClick={onDismiss} />
        </RowBetween> */}
        <MainContent gap="32px" justify="center">
          {/* <AlertTriangle color={theme.red1} style={{ strokeWidth: 1.5 }} size={64} /> */}
          <SideChainImgWrapper>
            <img src={sidechainImg} alt="" />
          </SideChainImgWrapper>
          <div>
            <TitleTransactionError fontWeight={600} fontSize={20}>
              {message.title}
            </TitleTransactionError>
            <MessageTransactionError color="var(--mc-color-light-7)" fontWeight={400} fontSize={14}>
              {message.message}
            </MessageTransactionError>
          </div>
        </MainContent>
      </Section>
      {/* <BottomSection gap="12px"> */}
      {/* <ButtonPrimary onClick={onDismiss}> */}
      <ButtonTryAgainWrapper>
        <ButtonTryAgain intent="secondary" size="large" onClick={onDismiss}>
          {/* <Trans>Try again</Trans> */}
          Try again
        </ButtonTryAgain>
      </ButtonTryAgainWrapper>
      {/* </ButtonPrimary> */}
      {/* </BottomSection> */}
    </ContainerTransactionError>
  )
}

interface ConfirmationModalProps {
  isOpen: boolean
  onDismiss: () => void
  hash: string | undefined
  content: () => ReactNode
  attemptingTxn: boolean
  maxWidth?: number
  hasRelatedIcon?: boolean
  isSupplying?: boolean
  pendingText: ReactNode
  currencyToAdd?: Currency | undefined
  trade?: { tokenA?: Currency; tokenB?: Currency }
}

export default function TransactionConfirmationModal({
  isOpen,
  onDismiss,
  attemptingTxn,
  hash,
  pendingText,
  maxWidth = 416,
  isSupplying,
  hasRelatedIcon,
  trade,
  content,
  currencyToAdd,
}: ConfirmationModalProps) {
  const { chainId } = useActiveWeb3React()

  if (!chainId) return null

  // confirmation screen
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxHeight={95} maxWidth={maxWidth}>
      {attemptingTxn ? (
        <ConfirmationPendingContent
          onDismiss={onDismiss}
          pendingText={pendingText}
          trade={trade}
          hasRelatedIcon={hasRelatedIcon}
          isSupplying={isSupplying}
        />
      ) : hash ? (
        <TransactionSubmittedContent
          chainId={chainId}
          hash={hash}
          onDismiss={onDismiss}
          currencyToAdd={currencyToAdd}
        />
      ) : (
        content()
      )}
    </Modal>
  )
}
