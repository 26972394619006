import { Token, WETH9 } from '@uniswap/sdk-core'
import { SupportedChainId } from './chains'
import { Ronin } from './ron'

export const WRON_TESTNET = new Token(2021, '0xa959726154953bae111746e265e6d754f48570e6', 18, 'WRON', 'Wrapped Ronin')
export const WETH_TESTNET = new Token(
  2021,
  '0x29c6f8349a028e1bdfc68bfa08bdee7bc5d47e16',
  18,
  'WETH',
  'Ronin Wrapped Ether'
)
export const AXS_TESTNET = new Token(
  2021,
  '0x3c4e17b9056272ce1b49f6900d8cfd6171a1869d',
  18,
  'AXS',
  'Axie Infinity Shard'
)
export const SLP_TESTNET = new Token(2021, '0x82f5483623d636bc3deba8ae67e1751b6cf2bad2', 0, 'SLP', 'Smooth Love Potion')
export const AXSETH_LP_TESTNET = new Token(
  2021,
  '0x985e873443d72711d31b190fd975f26fa77e3840',
  18,
  'AXS-WETH LP',
  'AXS-WETH LP'
)
export const SLPETH_LP_TESTNET = new Token(
  2021,
  '0x44dc0fd4348acc84829f7bd0348c9ddcfe31d003',
  18,
  'SLP-WETH LP',
  'SLP-WETH LP'
)
export const RONETH_LP_TESTNET = new Token(
  2021,
  '0xfd25f2eb3d5ead58b47cd4af645f1c136d8f0455',
  18,
  'RON-WETH LP',
  'RON-WETH LP'
)

export const RONAXS_LP_TESTNET = new Token(
  2021,
  '0x3152B7c67036d4E09B4e271588E868Cb25d95B69',
  18,
  'RON-AXS LP',
  'RON-AXS LP'
)

export const RONSLP_LP_TESTNET = new Token(
  2021,
  '0xd40c313b2a6d50afdf1404004e594d743420661c',
  18,
  'RON-SLP LP',
  'RON-SLP LP'
)

export const RONUSDC_LP_TESTNET = new Token(
  2021,
  '0x742D8E4C5bbBe3deAC714D436378384d4378323e',
  18,
  'RON-USDC LP',
  'RON-USDC LP'
)

export const USDT_TESTNET = new Token(2021, '0x04ef1d4f687bb20eedcf05c7f710c078ba39f328', 18, 'USDT', 'USDT')
export const USDC_TESTNET = new Token(2021, '0x067fbff8990c58ab90bae3c97241c5d736053f77', 6, 'USDC', 'USD Coin')

export const WRON = new Token(2020, '0xe514d9deb7966c8be0ca922de8a064264ea6bcd4', 18, 'RON', 'Ronin')
export const WETH = new Token(2020, '0xc99a6a985ed2cac1ef41640596c5a5f9f4e19ef5', 18, 'WETH', 'Ronin Wrapped Ether')
export const AXS = new Token(2020, '0x97a9107c1793bc407d6f527b77e7fff4d812bece', 18, 'AXS', 'Axie Infinity Shard')
export const SLP = new Token(2020, '0xa8754b9fa15fc18bb59458815510e40a12cd2014', 0, 'SLP', 'Smooth Love Potion')
export const USDC = new Token(2020, '0x0b7007c13325c48911f73a2dad5fa5dcbf808adc', 6, 'USDC', 'USD Coin')

export const AXSETH_LP = new Token(2020, '0xc6344bc1604fcab1a5aad712d766796e2b7a70b9', 18, 'AXS/ETH LP', 'AXS/ETH LP')
export const SLPETH_LP = new Token(2020, '0x306a28279d04a47468ed83d55088d0dcd1369294', 18, 'SLP/ETH LP', 'SLP/ETH LP')
export const RONETH_LP = new Token(2020, '0x2ecb08f87f075b5769fe543d0e52e40140575ea7', 18, 'RON-WETH LP', 'RON-WETH LP')
export const RONAXS_LP = new Token(2020, '0x32d1dbb6a4275133cc49f1c61653be3998ada4ff', 18, 'RON-AXS LP', 'RON-AXS LP')
export const RONSLP_LP = new Token(2020, '0x8f1c5eda143fa3d1bea8b4e92f33562014d30e0d', 18, 'RON-SLP LP', 'RON-SLP LP')
export const RONUSDC_LP = new Token(
  2020,
  '0x4f7687affc10857fccd0938ecda0947de7ad3812',
  18,
  'RON-USDC LP',
  'RON-USDC LP'
)

export const WETH9_EXTENDED: { [chainId: number]: Token } = {
  ...WETH9,
  [SupportedChainId.RONIN_MAINNET]: WRON,
  [SupportedChainId.RONIN_TESTNET]: WRON_TESTNET,
}

export const RONIN_WETH: { [chainId: number]: Token } = {
  [SupportedChainId.RONIN_TESTNET]: WETH_TESTNET,
  [SupportedChainId.RONIN_MAINNET]: WETH,
}

export class ExtendedEther extends Ronin {
  public get wrapped(): Token {
    if (this.chainId in WETH9_EXTENDED) return WETH9_EXTENDED[this.chainId]
    throw new Error('Unsupported chain ID')
  }

  public static onChain(chainId: number): ExtendedEther {
    return new ExtendedEther(chainId)
  }
}

export const tokenAddressByChain = (chainId: string | number) =>
  ({
    '2020': {
      WRON,
      AXS,
      SLP,
      WETH,
      USDC,
      AXSETH_LP,
      SLPETH_LP,
      RONETH_LP,
      AXSRON_LP: RONAXS_LP,
      RONSLP_LP: RONSLP_LP,
      RONUSDC_LP: RONUSDC_LP,
    },
    '2021': {
      WRON: WRON_TESTNET,
      AXS: AXS_TESTNET,
      SLP: SLP_TESTNET,
      WETH: WETH_TESTNET,
      USDC: USDC_TESTNET,
      AXSETH_LP: AXSETH_LP_TESTNET,
      SLPETH_LP: SLPETH_LP_TESTNET,
      RONETH_LP: RONETH_LP_TESTNET,
      AXSRON_LP: RONAXS_LP_TESTNET,
      RONSLP_LP: RONSLP_LP_TESTNET,
      RONUSDC_LP: RONUSDC_LP_TESTNET,
    },
  }[chainId])

export const tokenPairsByChain = (chainId: string | number): { [key: string]: string[] } | undefined =>
  ({
    '2020': {
      WETH: ['AXS', 'SLP', 'USDC', 'RON'],
      RON: ['WETH', 'USDC', 'AXS', 'SLP'],
      AXS: ['WETH', 'RON'],
      SLP: ['WETH', 'RON'],
      WRON: ['WETH'],
      USDC: ['WETH', 'RON'],
    },
    '2021': {
      WETH: ['AXS', 'SLP', 'USDC', 'RON'],
      RON: ['WETH', 'USDC', 'AXS', 'SLP'],
      AXS: ['WETH', 'RON'],
      SLP: ['WETH', 'RON'],
      WRON: ['WETH'],
      USDC: ['WETH', 'RON'],
    },
  }[chainId])
