import { Button } from '@axieinfinity/mochi'
import { NotebookIcon } from '@axieinfinity/mochi-icons'
import { AutoColumn } from 'components/Column'
import { FarmPoolList } from 'components/FarmPoolList/FarmPoolList'
import styled from 'styled-components/macro'

const PageWrapper = styled(AutoColumn)`
  max-width: 992px;
  width: 100%;
  padding-right: var(--mc-space-inline-16x);
  padding-left: var(--mc-space-inline-16x);
`
const Title = styled.div`
  display: flex;
  gap: var(--mc-space-inset-12x);
  flex-direction: column;
  align-items: flex-start;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
`
const MainTitle = styled.h4`
  text-transform: uppercase;
  font-weight: var(--mc-font-weight-semi-bold);
`
const SubTitle = styled.div`
  font-size: var(--mc-font-size-normal);
  line-height: var(--mc-line-height-medium);
`
const FarmPoolContainer = styled.div`
  display: flex;
  gap: var(--mc-space-inset-20x);
  margin-top: var(--mc-space-stack-16x);
  flex-direction: column;
  align-items: stretch;
  @media (min-width: 768px) {
    flex-direction: row;
    align-items: flex-start;
  }
`
export const Farm = () => {
  return (
    <>
      <PageWrapper>
        <Title>
          <div>
            <MainTitle>Farm</MainTitle>
            <SubTitle>Let&apos;s farm RON with your LP tokens!</SubTitle>
          </div>

          <a href="https://axie-infinity.gitbook.io/katana-docs/" target="_blank" rel="noopener noreferrer">
            <Button intent="primary" minimal leftIcon={NotebookIcon} size="small">
              What are LP tokens?
            </Button>
          </a>
        </Title>

        <FarmPoolContainer>
          <FarmPoolList />
        </FarmPoolContainer>
      </PageWrapper>
    </>
  )
}
