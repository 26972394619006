import { CurrencyAmount, Percent, Token } from '@uniswap/sdk-core'
import { Pair } from '@uniswap/v2-sdk'
import JSBI from 'jsbi'
import { darken } from 'polished'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { useTotalSupply } from '../../hooks/useTotalSupply'

import { useActiveWeb3React } from '../../hooks/web3'
import { useTokenBalance } from '../../state/wallet/hooks'
import { currencyId } from '../../utils/currencyId'
import { unwrappedToken } from '../../utils/unwrappedToken'

import { useColor } from '../../hooks/useColor'

import { Button } from '@axieinfinity/mochi'
import { CaretDownIcon, CaretUpIcon, FilledPieChartIcon } from '@axieinfinity/mochi-icons'
import { BIG_INT_ZERO } from '../../constants/misc'
import Card, { LightCard } from '../Card'
import { AutoColumn } from '../Column'
import CurrencyLogo from '../CurrencyLogo'
import DoubleCurrencyLogo from '../DoubleLogo'
import { AutoRow, RowBetween } from '../Row'
import { Dots } from '../swap/styleds'

export const FixedHeightRow = styled(RowBetween)`
  height: 24px;
`

export const HoverCard = styled(Card)`
  border: 1px solid transparent;
  :hover {
    border: 1px solid ${({ theme }) => darken(0.06, theme.bg2)};
  }
`
const StyledPositionCard = styled(LightCard)`
  border: none;
  position: relative;
  overflow: hidden;
`

const ContainerForMinimal = styled.div`
  background-color: var(--mc-color-light-1);
  border-radius: var(--mc-space-inset-8x);
  width: 100%;
  padding: var(--mc-space-inset-12x);
  padding-right: var(--mc-space-inline-24x);
  padding-bottom: var(--mc-space-stack-20x);
  margin-right: auto;
  margin-left: auto;
`
const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: var(--mc-space-stack-16x);
  gap: var(--mc-space-inline-4x);
`
const HeaderTitle = styled.div`
  color: var(--mc-color-light-7);
  font-weight: var(--mc-font-weight-bold);
  font-size: var(--mc-font-size-x-small);
  line-height: var(--mc-line-height-normal);
  text-transform: uppercase;
`
const Body = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
    margin-left: var(--mc-space-inline-16x);
  }
`
const PoolBalanceContainer = styled.div`
  flex: 1 1 0%;
  text-align: center;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  margin: 0 auto;
`
const PoolBalanceContainerMinimal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const BalanceAmountMinimal = styled.div`
  font-size: var(--mc-font-size-large);
  line-height: var(--mc-line-height-x-large);
  font-weight: var(--mc-font-weight-semi-bold);
`
const BalanceAmount = styled(BalanceAmountMinimal)`
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`

const CardContainer = styled.div`
  flex: 1 1 0%;
  @media (min-width: 768px) {
    margin-left: var(--mc-space-inline-24x);
  }
`
const TokenDepositedCard = styled.div`
  border-radius: var(--mc-space-inset-8x);
  background-color: var(--mc-color-light-3);
  padding: var(--mc-space-stack-4x) var(--mc-space-inline-8x);
  display: flex;
  margin-bottom: var(--mc-space-stack-8x);
`
const TokenDepositedAmount = styled.div`
  font-size: var(--mc-font-size-normal);
  line-height: var(--mc-line-height-medium);
  margin-left: var(--mc-space-inline-4x);
  color: var(--mc-color-light-7);
`
const PoolTokenPercentageCard = styled.div`
  border-radius: var(--mc-space-inset-8x);
  background-color: var(--mc-color-light-3);
  padding: var(--mc-space-stack-4x) var(--mc-space-inline-8x);
`
const Wrapper = styled.div`
  color: var(--mc-color-light-7);
  font-size: var(--mc-font-size-normal);
  line-height: var(--mc-line-height-medium);
`
const HeaderPoolCard = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  border-radius: var(--mc-space-inset-8x);
  padding: var(--mc-space-inset-8x);
  padding-right: var(--mc-space-inline-4x);
  background-color: var(--mc-color-light-2);
`
const TitlePoolCard = styled.div`
  display: flex;
`
const CurrencySymbol = styled.div`
  font-size: var(--mc-font-size-medium);
  line-height: var(--mc-line-height-large);
  font-weight: var(--mc-font-weight-semi-bold);
  color: var(--mc-color-light-7);
  margin-left: var(--mc-space-inline-4x);
`
const ToggleShowDetail = styled.div`
  display: flex;
  align-items: center;
  font-size: var(--mc-font-size-normal);
  line-height: var(--mc-line-height-medium);
  font-weight: var(--mc-font-weight-semi-bold);
  color: var(--mc-color-light-8);
  margin-right: var(--mc-space-inline-16x);
  gap: var(--mc-space-inline-8x);
`
const PoolCardDetail = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  border-width: var(--mc-space-inset-x);
  border-color: var(--mc-color-light-3);
  border-radius: var(--mc-space-inset-8x);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top-width: 0px;
  margin-top: calc(var(--mc-space-stack-8x) * -1);
  padding: var(--mc-space-stack-24x);
  padding-bottom: var(--mc-space-stack-12x);
  @media (min-width: 768px) {
    flex-direction: row;
  }
`
const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  @media (min-width: 768px) {
    flex-direction: row;
    flex-grow: 1;
  }
`
const PooledInfoContainer = styled.div`
  flex: 1 1 0%;
  @media (min-width: 768px) {
    margin-left: var(--mc-space-inline-12x);
    flex-grow: 1;
  }
`
const TokenDepositedRow = styled.div`
  display: flex;
  margin-bottom: var(--mc-space-stack-16x);
  width: 100%;
`
const SubHeader = styled.div`
  color: var(--mc-color-light-7);
  font-size: var(--mc-font-size-normal);
  line-height: var(--mc-line-height-medium);
  text-align: right;
`
const SubHeaderNoWrap = styled(SubHeader)`
  white-space: nowrap;
`

const DepositedAmountContainer = styled.div`
  display: flex;
  font-weight: var(--mc-font-weight-semi-bold);
  font-size: var(--mc-font-size-normal);
  line-height: var(--mc-line-height-medium);
  flex: 1 1 0%;
  margin-left: var(--mc-space-inline-8x);
`
const PoolSharePercentageContainer = styled(DepositedAmountContainer)`
  white-space: nowrap;
  gap: 4px;
`
const UserPoolShareRow = styled.div`
  display: flex;
  width: 100%;
`
const ButtonLinkContainer = styled.div`
  display: flex;
  flex-shrink: 1;
  margin-top: var(--mc-space-stack-16x);
  @media (min-width: 768px) {
    margin-top: 0;
  }
  gap: var(--mc-space-inline-8x);
`
interface PositionCardProps {
  pair: Pair
  showUnwrapped?: boolean
  border?: string
  stakedBalance?: CurrencyAmount<Token> // optional balance to indicate that liquidity is deposited in mining pool
}

export function MinimalPositionCard({ pair, showUnwrapped = false, border }: PositionCardProps) {
  const { account } = useActiveWeb3React()

  const currency0 = showUnwrapped ? pair.token0 : unwrappedToken(pair.token0)
  const currency1 = showUnwrapped ? pair.token1 : unwrappedToken(pair.token1)

  const [showMore, setShowMore] = useState(false)

  const userPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
  const totalPoolTokens = useTotalSupply(pair.liquidityToken)

  const poolTokenPercentage =
    !!userPoolBalance &&
    !!totalPoolTokens &&
    JSBI.greaterThanOrEqual(totalPoolTokens.quotient, userPoolBalance.quotient)
      ? new Percent(userPoolBalance.quotient, totalPoolTokens.quotient)
      : undefined

  const [token0Deposited, token1Deposited] =
    !!pair &&
    !!totalPoolTokens &&
    !!userPoolBalance &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalPoolTokens.quotient, userPoolBalance.quotient)
      ? [
          pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
          pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false),
        ]
      : [undefined, undefined]

  return (
    <>
      {userPoolBalance && JSBI.greaterThan(userPoolBalance.quotient, JSBI.BigInt(0)) ? (
        <>
          <ContainerForMinimal>
            <Header>
              <FilledPieChartIcon size={16} color="var(--mc-color-light-7)" />
              <HeaderTitle>
                {/* <Trans>Your position</Trans> */}
                Your position
              </HeaderTitle>
            </Header>
            <Body>
              <PoolBalanceContainerMinimal>
                <DoubleCurrencyLogo currency0={currency0} currency1={currency1} size={48} />
                <BalanceAmountMinimal>{userPoolBalance ? userPoolBalance.toSignificant(4) : '-'}</BalanceAmountMinimal>
              </PoolBalanceContainerMinimal>
              <CardContainer>
                <TokenDepositedCard>
                  <CurrencyLogo currency={currency0} size="20px" />
                  <TokenDepositedAmount>{token0Deposited?.toSignificant(6)}</TokenDepositedAmount>
                </TokenDepositedCard>
                <TokenDepositedCard>
                  <CurrencyLogo currency={currency1} size="20px" />
                  <TokenDepositedAmount>{token1Deposited?.toSignificant(6)}</TokenDepositedAmount>
                </TokenDepositedCard>
                <PoolTokenPercentageCard>
                  <Wrapper>
                    <strong>Pool: </strong>
                    {poolTokenPercentage
                      ? poolTokenPercentage.toFixed(2) === '0.00'
                        ? '< 0.01%'
                        : poolTokenPercentage.toFixed(2) + '%'
                      : '-'}
                  </Wrapper>
                </PoolTokenPercentageCard>
              </CardContainer>
            </Body>
          </ContainerForMinimal>
        </>
      ) : null}
    </>
  )
}

export default function FullPositionCard({ pair, border, stakedBalance }: PositionCardProps) {
  const { account } = useActiveWeb3React()

  const currency0 = unwrappedToken(pair.token0)
  const currency1 = unwrappedToken(pair.token1)

  const [showMore, setShowMore] = useState(false)

  const userDefaultPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
  const totalPoolTokens = useTotalSupply(pair.liquidityToken)

  // if staked balance balance provided, add to standard liquidity amount
  const userPoolBalance = stakedBalance ? userDefaultPoolBalance?.add(stakedBalance) : userDefaultPoolBalance

  const poolTokenPercentage =
    !!userPoolBalance &&
    !!totalPoolTokens &&
    JSBI.greaterThanOrEqual(totalPoolTokens.quotient, userPoolBalance.quotient)
      ? new Percent(userPoolBalance.quotient, totalPoolTokens.quotient)
      : undefined

  const [token0Deposited, token1Deposited] =
    !!pair &&
    !!totalPoolTokens &&
    !!userPoolBalance &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalPoolTokens.quotient, userPoolBalance.quotient)
      ? [
          pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
          pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false),
        ]
      : [undefined, undefined]

  const backgroundColor = useColor(pair?.token0)

  return (
    <StyledPositionCard borderRadius="8px" style={{ marginTop: 12, background: '#fff', padding: 0 }}>
      <AutoColumn>
        <HeaderPoolCard onClick={() => setShowMore(!showMore)}>
          <AutoRow padding="0px">
            <TitlePoolCard>
              <DoubleCurrencyLogo currency0={currency0} currency1={currency1} size={24} />
              <CurrencySymbol>
                {!currency0 || !currency1 ? (
                  <Dots>
                    {/* <Trans>Loading</Trans> */}
                    Loading
                  </Dots>
                ) : (
                  `${currency0.symbol}/${currency1.symbol}`
                )}
              </CurrencySymbol>
            </TitlePoolCard>
          </AutoRow>
          {/* <Button
            minimal
            size={Button.sizes.Small}
            onClick={() => setShowMore(!showMore)}
            rightIcon={showMore ? CaretUpIcon : CaretDownIcon}
          > */}
          <ToggleShowDetail>
            {/* <Trans>Manage</Trans> */}
            Manage
            {showMore ? <CaretUpIcon size={20} /> : <CaretDownIcon size={20} />}
          </ToggleShowDetail>
          {/* </Button> */}
          {/* <RowFixed gap="8px" style={{ marginRight: '4px' }}>
            <ButtonEmpty padding="6px 8px" borderRadius="12px" width="100%" onClick={() => setShowMore(!showMore)}>
              {showMore ? (
                <>
                  <Trans>Manage</Trans>
                  <ChevronUp size="20" style={{ marginLeft: '8px', height: '20px', minWidth: '20px' }} />
                </>
              ) : (
                <>
                  <Trans>Manage</Trans>
                  <ChevronDown size="20" style={{ marginLeft: '8px', height: '20px', minWidth: '20px' }} />
                </>
              )}
            </ButtonEmpty>
          </RowFixed> */}
        </HeaderPoolCard>

        {showMore && (
          <>
            <PoolCardDetail>
              <MainContent>
                <PoolBalanceContainer style={{ maxWidth: 172 }}>
                  <DoubleCurrencyLogo currency0={currency0} currency1={currency1} size={48} />
                  <BalanceAmount>{userPoolBalance ? userPoolBalance.toSignificant(4) : '-'}</BalanceAmount>
                </PoolBalanceContainer>
                <PooledInfoContainer>
                  <TokenDepositedRow>
                    <SubHeader style={{ width: 107 }}>
                      {/* <Trans>Pooled {currency0.symbol}:</Trans> */}
                      Pooled {currency0.symbol}:
                    </SubHeader>
                    <DepositedAmountContainer>
                      <CurrencyLogo size="20px" style={{ marginRight: 4 }} currency={currency0} />
                      {!currency0?.decimals
                        ? token0Deposited?.toExact()
                        : token0Deposited?.toFixed(4) === '0.0000'
                        ? '< 0.0001'
                        : token0Deposited?.toSignificant(6)}
                    </DepositedAmountContainer>
                  </TokenDepositedRow>
                  <TokenDepositedRow>
                    <SubHeader style={{ width: 107 }}>
                      {/* <Trans>Pooled {currency1.symbol}:</Trans> */}
                      Pooled {currency1.symbol}:
                    </SubHeader>
                    <DepositedAmountContainer>
                      <CurrencyLogo size="20px" style={{ marginRight: 4 }} currency={currency1} />
                      {!currency1.decimals
                        ? token1Deposited?.toExact()
                        : token1Deposited?.toFixed(4) === '0.0000'
                        ? '< 0.0001'
                        : token1Deposited?.toSignificant(6)}
                    </DepositedAmountContainer>
                  </TokenDepositedRow>
                  <UserPoolShareRow>
                    <SubHeaderNoWrap style={{ width: 107 }}>
                      {/* <Trans>Your pool share:</Trans> */}
                      Your pool share:
                    </SubHeaderNoWrap>
                    <PoolSharePercentageContainer>
                      <FilledPieChartIcon size={20} color="var(--mc-color-light-7)" />

                      {poolTokenPercentage ? (
                        <>
                          {/* <Trans> */}
                          {poolTokenPercentage.toFixed(2) === '0.00' ? '<0.01' : poolTokenPercentage.toFixed(2)}%
                          {/* </Trans> */}
                        </>
                      ) : (
                        '--'
                      )}
                    </PoolSharePercentageContainer>
                  </UserPoolShareRow>
                </PooledInfoContainer>
              </MainContent>

              {userDefaultPoolBalance && JSBI.greaterThan(userDefaultPoolBalance.quotient, BIG_INT_ZERO) && (
                <ButtonLinkContainer>
                  <Link to={`/remove/${currencyId(currency0)}/${currencyId(currency1)}`}>
                    <Button intent="danger" minimal size="small">
                      Remove
                    </Button>
                  </Link>
                  <Link to={`/add/${currencyId(currency0)}/${currencyId(currency1)}`}>
                    <Button intent="secondary" size="small">
                      Add
                    </Button>
                  </Link>
                </ButtonLinkContainer>
              )}
            </PoolCardDetail>
            <AutoColumn gap="8px"></AutoColumn>
          </>
        )}
      </AutoColumn>
    </StyledPositionCard>
  )
}
