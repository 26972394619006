import { Currency, Token } from '@uniswap/sdk-core'
import { TokenList } from '@uniswap/token-lists/dist/types'
import Card from 'components/Card'
import { AutoColumn } from 'components/Column'
import { RowBetween } from 'components/Row'
import useTheme from 'hooks/useTheme'
import { useActiveWeb3React } from 'hooks/web3'
import { transparentize } from 'polished'
import { useAddUserToken } from 'state/user/hooks'
import styled from 'styled-components/macro'
import { TYPE } from 'theme'
import { ExternalLink } from '../../theme/components'
import { ExplorerDataType, getExplorerLink } from '../../utils/getExplorerLink'
import { PaddedColumn } from './styleds'

import { Button } from '@axieinfinity/mochi'
import { ArrowLeftIcon, CrossIcon, FilledQuestionIcon } from '@axieinfinity/mochi-icons'
import MagnifierImg from 'assets/images/magnifier.png'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: auto;
`

const WarningWrapper = styled(Card)<{ highWarning: boolean }>`
  background-color: ${({ theme, highWarning }) =>
    highWarning ? transparentize(0.8, theme.red1) : transparentize(0.8, theme.yellow2)};
  width: fit-content;
`

const AddressText = styled(TYPE.blue)`
  font-size: 12px;
  word-break: break-all;
  color: var(--mc-color-blue-5);
  font-weight: var(--mc-font-weight-normal);

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 10px;
`}
`
const FilledQuestionWrapper = styled.div`
  margin-right: 12px;
  min-width: 32px;
  display: inline-block;
  fill: var(--mc-color-yellow-5);
  color: var(--mc-color-yellow-5);
`

const BackToSwapButton = styled(Button)`
  margin-left: auto;
  margin-right: auto;
  margin-top: 12px;
  max-width: 132px;
`

const BodyContent = styled(TYPE.body)`
  color: var(--mc-color-light-7);
  margin-top: calc(var(--mc-space-stack-4x) * -1);
`
const CardToken = styled(Card)`
  margin-left: auto;
  margin-right: auto;
  border-radius: var(--mc-space-inset-8x);
  display: flex;
  margin-top: var(--mc-space-stack-12x);
  align-items: center;
  max-width: 356px;
`
interface ImportProps {
  tokens: Token[]
  list?: TokenList
  onBack?: () => void
  onDismiss?: () => void
  handleCurrencySelect?: (currency: Currency) => void
}

export function ImportToken({ tokens, list, onBack, onDismiss, handleCurrencySelect }: ImportProps) {
  const theme = useTheme()

  const { chainId } = useActiveWeb3React()

  const addToken = useAddUserToken()

  return (
    <Wrapper>
      <PaddedColumn gap="14px" style={{ width: '100%', flex: '1 1', paddingBottom: 0 }}>
        <RowBetween>
          {onBack ? <ArrowLeftIcon style={{ cursor: 'pointer' }} onClick={onBack} /> : <div />}
          <TYPE.mediumHeader>
            {/* <Plural value={tokens.length} one="Import token" other="Import tokens" /> */}
          </TYPE.mediumHeader>
          {onDismiss ? <CrossIcon onClick={onDismiss} size={24} /> : <div />}
        </RowBetween>
      </PaddedColumn>
      {/* <SectionBreak /> */}
      <AutoColumn gap="md" style={{ marginBottom: '14px', padding: '1rem', paddingTop: '4px' }}>
        <img src={MagnifierImg} alt="" width={160} className="mx-auto" />
        <span className="-mt-12 font-semibold leading-28 text-20 text-center">Invalid token address</span>
        {/* <AutoColumn justify="center" style={{ textAlign: 'center', gap: '16px', padding: '1rem' }}> */}
        {/* <AlertCircle size={48} stroke={theme.text2} strokeWidth={1} /> */}
        <BodyContent fontWeight={400} fontSize={14} textAlign="center" lineHeight="20px">
          {/* <Trans> */}
          This token is not supported yet.
          {/* </Trans> */}
        </BodyContent>
        {/* </AutoColumn> */}
        {tokens.map((token) => {
          return (
            <CardToken
              backgroundColor="var(--mc-color-yellow-1)"
              className="token-warning-container"
              key={'import' + token.address}
              padding="16px"
            >
              <FilledQuestionWrapper>
                <FilledQuestionIcon size={32} />
              </FilledQuestionWrapper>
              <AutoColumn gap="10px" justify="center">
                {/* <CurrencyLogo currency={token} size={'32px'} /> */}

                {/* <AutoColumn gap="4px" justify="center">
                  <TYPE.body ml="8px" mr="8px" fontWeight={500} fontSize={20}>
                    {token.symbol}
                  </TYPE.body>
                  <TYPE.darkGray fontWeight={400} fontSize={14}>
                    {token.name}
                  </TYPE.darkGray>
                </AutoColumn> */}
                {chainId && (
                  <ExternalLink href={getExplorerLink(chainId, token.address, ExplorerDataType.ADDRESS)}>
                    <AddressText fontWeight={400} fontSize={14}>
                      {token.address.replace('0x', 'ronin:')}
                    </AddressText>
                  </ExternalLink>
                )}
                {/* {list !== undefined ? (
                  <RowFixed>
                    {list.logoURI && <ListLogo logoURI={list.logoURI} size="16px" />}
                    <TYPE.small ml="6px" fontSize={14} color={theme.text3}>
                      <Trans>via {list.name} token list</Trans>
                    </TYPE.small>
                  </RowFixed>
                ) : (
                  <WarningWrapper borderRadius="4px" padding="4px" highWarning={true}>
                    <RowFixed>
                      <AlertCircle stroke={theme.red1} size="10px" />
                      <TYPE.body color={theme.red1} ml="4px" fontSize="10px" fontWeight={500}>
                        <Trans>Unknown Source</Trans>
                      </TYPE.body>
                    </RowFixed>
                  </WarningWrapper>
                )} */}
              </AutoColumn>
            </CardToken>
          )
        })}

        {/* <ButtonPrimary
          altDisabledStyle={true}
          borderRadius="20px"
          padding="10px 1rem"
          onClick={() => {
            tokens.map((token) => addToken(token))
            handleCurrencySelect && handleCurrencySelect(tokens[0])
          }}
          className=".token-dismiss-button"
        >
          <Trans>Import</Trans>
        </ButtonPrimary> */}

        <BackToSwapButton onClick={onDismiss}>Back to Swap</BackToSwapButton>
      </AutoColumn>
    </Wrapper>
  )
}
